<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="8">
				<a-col :span="9">
					<a-button type="primary" @click="create()" v-if="isGranted('holiday_rules_add')">
						<a-icon type="plus" />
						<span>{{ l('Create') }}</span>
					</a-button>
					<a-button type="primary" @click="edit(selectedRows)" v-if="isGranted('holiday_rules_edit')">
						<a-icon type="edit" />
						<span>{{ l('Edit') }}</span>
					</a-button>
					<a-button type="danger" @click="Delete()" v-if="isGranted('holiday_rules_delete')">
						<a-icon type="delete" />
						<span>{{ l('Delete') }}</span>
					</a-button>
				</a-col>
				<a-col :span="12" :offset="3">
					<a-row :gutter="8">
						<!-- 搜索 -->
						<a-col :sm="24">
							<a-form-item>
								<a-input-search
									style="width: 200px"
									name="filterText"
									:placeholder="l('SearchWithThreeDot')"
									@search="search"
									enterButton
									v-model="filterText"
									v-decorator="['filterText']"
								/>
							</a-form-item>
						</a-col>
					</a-row>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
				:dataSource="tableData"
				:customRow="customRow"
				:rowClassName="rowClassName"
			>
				<span slot="unitType" slot-scope="record">
					{{ dicMapHolidayUnit[record] }}
				</span>
				<span slot="isContainHoilday" slot-scope="record">
					{{ record ? '是' : '否' }}
				</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '@/shared/component-base';
import CreateOrEditHolidayRules from './create-or-edit-holiday-rules/create-or-edit-holiday-rules.vue';
import { Dic } from '@/shared/utils';
import { ModalHelper, HolidayRuleServiceProxy } from '@/shared';

export default {
	name: 'holiday-rules',
	mixins: [AppComponentBase],
	components: {
		CreateOrEditHolidayRules,
	},
	data() {
		return {
			columns: [
				{
					title: this.l('Code'),
					dataIndex: 'code',
					sorter: false,
					ellipsis: true,
					align: 'center',
				},
				{
					title: this.l('Name'),
					dataIndex: 'name',
					sorter: false,
					ellipsis: true,
					align: 'center',
				},
				{
					title: this.l('HolidayRulesUnit'),
					dataIndex: 'unitType',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'unitType' },
				},
				{
					title: this.l('HolidayRulesSmall'),
					dataIndex: 'minUnitNum',
					ellipsis: true,
					sorter: false,
					align: 'center',
				},
				{
					title: this.l('HolidayRulesYNContainLeave'),
					dataIndex: 'isContainHoilday',
					ellipsis: true,
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'isContainHoilday' },
				},
			],
			tableData: [],

			//假期单位字典
			dicHolidayUnit: [],
		};
	},
	computed: {
		//转换值，将字典code作为对象的属性
		dicMapHolidayUnit() {
			return this.dicHolidayUnit.reduce((obj, cur) => {
				obj[cur.itemDetailCode] = cur.itemDetailName;
				return obj;
			}, {});
		},
	},
	async created() {
		this._holidayRuleServiceProxy = new HolidayRuleServiceProxy(this.$apiUrl, this.$api);
		this.dicHolidayUnit = await Dic.getInstance().getDicAsync('HolidayUnitType');
		this.getData();
	},
	methods: {
		getData() {
			this.loading = true;
			this.selectedRowObj = {};

			this._holidayRuleServiceProxy
				.getPaged(this.filterText, this.request.sorting, this.request.maxResultCount, this.request.skipCount)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.totalItems = res.totalCount;
				});
		},
		//编辑或新增
		create(id) {
			ModalHelper.create(CreateOrEditHolidayRules, { id: id }, { width: '900px' }).subscribe((res) => {
				if (res) {
					// this.request.skipCount = 0;
					this.getData();
				}
			});
		},
		edit() {
			if (this.selectedRowKeys.length == 0) {
				abp.message.warn('请选择一项');
				return;
			}
			this.create(this.selectedRowKeys[0]);
		},
		//删除
		Delete() {
			if (this.selectedRows.length == 0) {
				abp.message.warn('请选择一项');
				return;
			}
			this.message.confirm('', `确认删除${this.selectedRows[0].name}？`, (res) => {
				if (res) {
					this.loading = true;
					this._holidayRuleServiceProxy
						.delete(this.selectedRows[0].id)
						.finally(() => {
							this.loading = false;
						})
						.then(() => {
							this.request.skipCount = 0;
							this.pageNumber = 1;
							this.getData();
							this.$notification['success']({
								message: this.l('SuccessfullyDeleted'),
							});
						});
				}
			});
		},
	},
};
</script>

<style scoped></style>
